const App = () => {
    return (
        <div className="wrap">
            <header className="header">
                <div className="header__container">
                    <div className="header__container-logo">
                        <img src="https://cdn.lawform.io/header/logo_l.svg" alt="로폼" />
                    </div>
                </div>
            </header>
            <section className="contents">
                <div className="contents__container">
                    <div className="contents__container-main_noti_info">
                        <div className="main_noti_info__icon_container">
                            <img src="https://cdn.lawform.io/downtime/icon_repair.svg" alt="정비" />
                        </div>
                        <div className="main_noti_info__main_txt">
                            로폼 <span className="text--blue_point">시스템 점검</span> 공지
                        </div>
                        <div className="main_noti_info__sub_txt">
                            더 나은 서비스를 제공하기 위한 시스템 점검 작업이 있어
                            <br />
                            아래와 같이 이용이 제한되오니 양해 부탁 드립니다.
                            <br />
                            빠르게 조치하여 곧 만나 뵙겠습니다.
                        </div>
                    </div>
                    <div className="contents__container-sub_noti_table">
                        <div className="sub_noti_table-contents_row">
                            <div className="sub_noti_table-title">점검일시</div>
                            <div id="name" className="sub_noti_table-content"></div>
                        </div>
                        <div className="horizontal--divide_line"></div>
                        <div className="sub_noti_table-contents_row">
                            <div className="sub_noti_table-title">작업내용</div>
                            <div id="content" className="sub_noti_table-content"></div>
                        </div>
                        <div className="horizontal--divide_line"></div>
                        <div className="sub_noti_table-contents_row">
                            <div className="sub_noti_table-title">제한내용</div>
                            <div id="redirect_url" className="sub_noti_table-content"></div>
                        </div>
                        <div className="horizontal--divide_line"></div>
                        <div className="sub_noti_table-add_info">
                            *작업 상황에 따라 일정은 변동 소지 있습니다.
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer">
                <div className="footer__container">
                    <div className="footer__container-customer_info">
                        <div className="customer_info-phone">
                            <div className="info-phone_title">고객센터</div>
                            <div className="info-phone_number">
                                <a href="tel:02-6925-0227">02-6925-0227</a>
                            </div>
                        </div>
                        <div className="customer_info-email">이메일 : contact@amicuslex.net</div>
                        <div className="customer_info-opration_info">
                            평일 10:00 ~ 18:00 (점심시간 12:30 - 13:30)
                        </div>
                    </div>
                    <div className="footer__container-company_info">
                        <div className="company_info-ci">
                            <img
                                src="https://cdn.lawform.io/footer/logo_amicuslex_white.svg"
                                alt="아미쿠스렉스"
                            />
                        </div>
                        <div className="compayn_info-copy">
                            &copy; 2022. Amicus Lex Inc. all rights reserved.
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default App
